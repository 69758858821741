/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "how-hearing-aid-apps-help-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-aid-apps-help-you",
    "aria-label": "how hearing aid apps help you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Hearing Aid Apps Help You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You no longer have to reach up to your ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "earing aids"), " to press a button, flip a switch, or turn a wheel in order to make an adjustment. Instead, you can discreetly make a few taps on a cell phone. Other people won’t be able to tell that you’re changing your hearing aid settings unless they view your smart phone screen. It will look like you’re checking your email or the weather report."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "functions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#functions",
    "aria-label": "functions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Functions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on the manufacturer, hearing aid apps provide several functions:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Change overall level of the sound with a volume control"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Adjust sound in different bands with an equalizer"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Adjust the noise reduction and directionality settings within a program"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Change the listening program that you are using"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Connect to accessories such as television streaming devices"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Request assistance from your hearing care professional"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Download new programs and updates from your hearing care professional"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Learn tips about hearing aid use and care"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rate the quality of the hearing aid settings"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Store the settings that you prefer for different situations"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Track your hearing aid battery life"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Locate your misplaced hearing aid using GPS"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You will still need to visit your hearing care professional in the office for the initial programming and major adjustments to your hearing aids. The hearing care professional uses their expertise to set the hearing aids to match your hearing loss and lifestyle. Apps allow you to make minor adjustments from these settings within a range. The range is set to ensure that you will not inadvertently hurt your hearing."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "apps-offered-by-hearing-aid-manufacturers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#apps-offered-by-hearing-aid-manufacturers",
    "aria-label": "apps offered by hearing aid manufacturers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Apps Offered by Hearing Aid Manufacturers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All the major hearing aid manufacturers offer apps for smart phones. Compatibility depends on the hearing aid make and style as well as the cell phone model. Consult with your hearing care professional for more details."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.signia-pro.com/en-us/chargers-and-accessories/accessories/",
    className: "c-md-a"
  }, "Signia"), ": myControl, myHearing, easyTek, touchControl"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.widex.com/en-us/hearing-aids/accessories/",
    className: "c-md-a"
  }, "Widex"), ": Beyond, Evoke, Tonelink"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.phonak.com/us/en/support/product-support.html",
    className: "c-md-a"
  }, "Phonak"), ": remoteControl, Remote"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.oticon.com/support/how-to/hearing-aid-app",
    className: "c-md-a"
  }, "Oticon"), ": ON app"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.rexton.com/en/",
    className: "c-md-a"
  }, "Rexton"), ": Smart Direct, Smart Remote, Smart Connect"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.resound.com/en-us/hearing-aids/apps",
    className: "c-md-a"
  }, "Resound"), ": Smart 3D, Smart, Control"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.unitron.com/content/unitron/tw/en/professional/practice-support/apps.html",
    className: "c-md-a"
  }, "Unitron"), ": Remote Plus, uControl"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.starkey.com/hearing-aids/apps",
    className: "c-md-a"
  }, "Starkey"), ": Thrive Hearing, TruLink Hearing Control"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "other-kinds-of-hearing-related-apps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-kinds-of-hearing-related-apps",
    "aria-label": "other kinds of hearing related apps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other Kinds of Hearing-Related Apps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many apps available that claim to test your hearing. However, these tests do not match the precision of professional hearing exams. Your hearing care professional provides tests with calibrated equipment and ensures that the headphones sit properly in your ears. Professional hearing tests are given in quiet environments that meet standards for the maximum noise present in different frequency bands. Your hearing care professional also performs a visual inspection of your ear canal and eardrums to make sure that you don’t have a medical problem."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some hearing aid manufacturers include tinnitus maskers within their hearing aid apps. In addition, there are stand-alone tinnitus masking apps. These apps help patients block out their tinnitus with pleasant sounds. You can choose among white noise, rainforest, and ocean sounds. Some apps offer educational content to help you learn to manage your tinnitus. Your hearing care professional can advise you on how to best use these apps."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A third kind of app that you can find are auditory training apps. These apps work as part of a hearing rehabilitation program to retrain your brain to hear better in challenging situations. You want to schedule several short practice sessions on these apps over a period of time. Think of auditory training as being similar to physical therapy. It takes time for your “auditory muscles” to get stronger."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-us-for-more-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-us-for-more-information",
    "aria-label": "contact us for more information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact Us for More Information"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sign up at hear.com and one of our experts will reach out to you for a telephone consultation. After we answer your initial questions, we can help you schedule a hearing evaluation appointment with one of our local hearing care professionals."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
